.portTable {
    table-layout: fixed;
    width: 100%;
    margin: 10px auto;
    border-collapse: collapse;
    border: 3px solid var(--color-bgTableaux);
    border-radius: 5px;
}
.portTable td {
  padding: 2px 3px;
  text-align: right;
}

.portTable td:first-of-type {
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  width:136px;
}

.portTable td:nth-of-type(2) {
  width:20%;
  text-align: right;
   
}
.portTable td:nth-of-type(4){
  width:15%;
  text-align: right;
   
}
.portTable td:nth-of-type(3),  td:nth-of-type(5){
  text-align: left;
}
 
 
.portTable tr:nth-last-of-type(even){
    background-color: var(--color-bgTableaux);
}

@media screen and ( min-width: 390px ) {
  .portTable{
    max-width: 400px;
  }
  .portTable td:first-of-type {
  width:40%;
  padding-right: 20px;
}
}



.minus{
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.75);
}