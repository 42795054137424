.buttonsContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

}

.buttonsContainer input {
    display: block;
    margin: 10px;
    width: 200px;
   
}