.manageStrategies table{
    table-layout: fixed;
    
    width: 200px;
    margin: 30px auto 0 auto;
    border-collapse: collapse;
    border: 2px solid var(--color-bgTableaux);
    position: relative;
     
}

.manageStrategies th, .manageStrategies td  {
  width:100px;
  text-align: center;
  border: 2px solid var(--color-bgTableaux);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.manageStrategies td {
  padding: 3px;
  text-align: right;
}

.manageStrategies th:first-of-type,
.manageStrategies td:first-of-type  {
  width:10px;
  padding-right:10px;
  text-align: center;
}

.manageStrategies th:nth-of-type(2),
.manageStrategies td:nth-of-type(2){
  width:40px;
}

.manageStrategies tr:nth-last-of-type(even){
    background-color: var(--color-bgTableaux);
}

.wrapper{
margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.btns{
  margin: auto;
}

.btns input[type="button"],
.btns input[type="submit"]{
  margin: 10px;
  padding: 10px;
}
.btns input[type="button"]:hover,
.btns input[type="submit"]:hover{
  padding: 9px;
}