/* Attention pour les dimensions du meter indispensable de passer
par des variables ( et de les setter 2* -> pour la div container et 
Pour le svg) */

.opacify {
  opacity: 0.2;
}

.wrapper_meter {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.alertInvalid {
  position: absolute;
  display:none; /* Hidden by default */
  z-index: 2;  
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.alertVisible{
  display: block;
}
.alertInvalid_content{
 background-color: #fefefe;
  margin: 50px auto; /* 15% from the top and centered */
  padding: 20px;
  border: 3px solid red;
  border-radius: 30px;
  width: fit-content;
}

.adjust .meter_container {
  text-align: center;
  width: var(--metW);
  height: var(--metH);
  margin: auto;
}
