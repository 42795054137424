.btns {
    composes: btns from "../managePortfolio.module.css";
} 

.form_existing{
    display: flex;
    flex-direction: raw;
    width: 100%;
    max-width: 400px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.form_existing label { 
    margin-top: 6px;
    display: inline-block;
    width: 45%;
    text-align: right;
}

.select_wrap{
    display: block;
    width:45%;
    text-align: left;
}

.infos{
    width: 100%;
}

.form_existing select,
.form_existing input[type="number"],
.form_existing input[type="text"] {
    display: inline-block;
    width: 150px;
    max-width: 150px;
    border-radius: 10px;
    padding: 4px;
    text-align: right;
    border: 1px solid black;
}