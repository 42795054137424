input:hover{
     cursor: pointer;
}
 
.myBtn {
    display: inline-block;
    margin: auto;
    width: auto;
    background-color: var(--color-1-light);
    font-family: var(--btn-font);
    color: var(--txt-color-dark2);
    text-transform: uppercase;
    /* font-weight: bold;   */
    letter-spacing: 0.5px;
    overflow: hidden;
    border-radius: 30px;
    border: solid rgba(0, 0, 0, 0.446) 1px;
    padding: 4px 10px 4px 10px ;
}
.myBtn:hover :not(disabled){
    cursor: pointer;
    border: solid black 2px;
    color: var(--txt-color-dark);
    padding: 3px 9px 3px 9px ;
}

input[type="submit"]
{
    background-color: var(--color-1-light);
}

.cancel{
    background-color: red;
    color: blanchedalmond;
}


*:disabled {
  background-color: lightgray !important;
  color: linen;
  opacity: 1;
}