.wrapper{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background: rgba(49,49,49,0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loader {
  transform: translateZ(1px);
}
.loader:after {
  content: '$';
  display: inline-block;
  width: 48px;
  height: 48px; 
  line-height: 48px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  font-size: 32px;
  font-weight: bold;
  background: #FFD700;
  color: #DAA520;
  border: 4px double ;
  box-sizing: border-box;
  box-shadow:  2px 2px 2px 1px  #FFD700;
  animation: coin-flip 4s cubic-bezier(0, 0.2, 0.8, 1) infinite; 
}

@keyframes coin-flip {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

/* ///////  loader 2 ////// */

.loader2{
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 2px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    opacity: 0.18;
    filter: blur(5px);
    animation: smokeOut 1s ease-in-out infinite alternate;
    text-shadow: 0 0 1px gray;
  }
  .loader2:before {
    content: "Chargement";
  }

  @keyframes smokeOut {
    100% {
      opacity: 1;
      filter:none;
      letter-spacing: 4px;
    }
  }