
/* Attention pour les dimensions du meter indispensable de passer
par des variables ( et de les setter 2* -> pour la div container et 
Pour le svg) */

.portfolio{
   
    --metW :80vw;
    --metH : calc((var(--metW))*0.58);
}

@media  screen and (min-width:480px) {
   .portfolio{
    --metW :400px;
    --metH :230px; 
    }
}

/* .portfolio h1{
    position: relative;
    top :-90px;
    left: calc(50vw);
    transform: translateX(-50%);
    white-space: nowrap;
    margin-bottom: -40px;
} */

:export {
    meterWidth: var(--metW);
    meterHeight: var(--metH);
}

.portfolio .meter_container {
     text-align: center;
    width: var(--metW);
    height: var(--metH); 
    margin: auto;
    /* text-align: center; */  
}

.under_table{
    margin-top: 10px;
    text-align: center;
}

.under_table a {
    width: 250px;
}
 