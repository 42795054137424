.signup {
    composes: sign from "../SignIn/sign.module.css";
}

.signup h1{
    top:-40px;
}

.signup img{
     margin: auto;
     width: 100px;
     height: 80px;
}

.signup p {
    text-align: center;
}

.signup a {
    display: inline;
    color: gray;
    
}
.signup input[type="checkbox"] {
    width: 50px;
    margin-bottom: 0;
}
 
.labelCheck{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.space{
    display: inline-block;
    width: 0px;
    vertical-align: middle;
}

.eye{
    composes: eye from "../SignIn/sign.module.css";
}

.parentOfPwd {
    composes: parentOfPwd from "../SignIn/sign.module.css";
}

.infos_cgi{
    font-size: 1rem;
    text-align: justify !important;
    margin: 10px 0;
}
.signup input[type="submit"] {
    height: 30px;
   color: black;
}
 