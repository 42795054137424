.sign {
    padding: 20px;
    text-align: center;
}

.sign h1 {
  
    top : -60px;
     

} 

.sign form {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 10px auto;
    text-align: left;
     
}

.sign input:not([submit]) {
    margin-bottom: 10px;
    height: 25px;
    padding: 2px;
    position: relative;
     
}
.sign input[type="submit"] {
    height: 35px;
   color: black;
}

.sign input{
    width: 300px;
}

.parentOfPwd{
    position: relative;
     width: 300px;
}

.eye {
    position: absolute;
    top:0;
    right: 0px;
    display: inline-block;
    cursor: pointer;
    width: 30px;
    height: 25px;
    margin-top: 2px;
     
}



.sign p {
    text-align: center;
}



.sign a {
    display: inline;
    color: black;
    background: transparent;
    font-weight: bolder;
    
}

.sign img {
    width: 300px;
    height: 240px;
}

.sign input[type="checkbox"] {
    width: 50px;
}
.labelCheck{
    display: flex;
    align-items: center;
    justify-content: center;
}
.space{
    display: inline-block;
    width: 20px;
    vertical-align: middle;
}