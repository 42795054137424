.myfooter{
    text-align: center;
    margin-top: 20px;
    background: var(--color-bgTableaux);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  
    border-top: rgba(0, 0, 0, 0.234) 1px solid;
     
   
}


.myfooter a {
    font-size: 1.2rem ;
    margin: 0 15px;
    width: 160px;
    background-color: #fff;
     
}