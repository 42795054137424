.exit{
    width: 300px;
    margin: auto;
}
/* .exit h1{
    position: relative;
    top :-90px;
    white-space: nowrap;
    margin-bottom: -40px;
}
  */
.form_exit {
    composes: form_enter from "../NewTrade/newTrade.module.css"
}
.input_wrap {
  display: block;
  width: 45%;
  text-align: left;
}

