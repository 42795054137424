.search form {
  margin: auto;
  text-align: center;
  font-size: larger;
}

.search input {
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.search table {
  table-layout: fixed;
  width: 100%;
  max-width: 480px;
  margin: auto;
  border-collapse: collapse;
  border: 3px solid var(--color-bgTableaux);
  position: relative;
}

.search tr {
   
  position: relative;
}

.search tbody {
  width: 100vw;
}

.search th:first-of-type,
.search td:first-of-type {
  width: 100px;

}

.search th:nth-of-type(2),
.search td:nth-of-type(2) {
  text-align: center;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search td:nth-of-type(2):hover {
  text-align: center;
  white-space: wrap;
  text-overflow: initial;
}


.search th:nth-of-type(3),
.search td:nth-of-type(3) {
  width: 70px;
  text-align: center;
  overflow: hidden;
  
}

.search td:nth-of-type(3):hover:after {
  width: fit-content;
  height: fit-content;
  content: attr(data-to-focus);
  background-color: rgb(237, 237, 237);
  color: black;
  position: absolute;
  top: 0px;
  right: 100px;
  padding: 5px;
  border-radius: 13px;
  line-height: 1.5;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}


.search th:nth-of-type(4),
.search td:nth-of-type(4) {
  width: 50px;
  text-align: center;
}

.search tr:nth-last-of-type(even) {
  background-color: var(--color-bgTableaux);
}

.search td,
.search th {
  padding: 5px 2px;
  border: 3px solid var(--color-bgTableaux);
}

.search tbody tr:hover td {
  cursor: pointer;
  background-color: var(--color-2-dark);
  color: var(--txt-color-light);
}