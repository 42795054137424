.btn_wrapper{
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: center;
   
}

.btn {
    display: block;
    width: 100px ;
    margin:  10px  10px !important;
}