.newTrade {
  text-align: center;
}

.newTrade h1 {
  
  top: -115px;
  
}

.noWrap {
  white-space: nowrap;
}

.full_width {
  width: 100%;
}

.form_enter {
  margin: 10px auto 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-width: 300px;
  max-width: 400px;
  justify-content: space-evenly;
  align-items: center;
  row-gap: 10px; 
}

.form_enter label:not(.label_wrap) {
  display: inline-block;
  width: 45%;
  text-align: right;
}

.label_wrap {
  width: 45%;
}
.input_wrap, .input_wrapLong {
  display: block;
  width: 45%;
  text-align: left;
}

@media (min-width: 768px) {
  .form_enter{
    width: 100%;
    max-width: 600px;
  }
  .label_wrap, .input_wrap {
    width: 24%;
  }
}

.form_enter input[type="number"],
.form_enter input[type="text"],
.form_enter textarea,
.form_enter select {
  display: inline-block;
  width: 85px;
  border-radius: 10px;
  padding: 4px;
  text-align: right;
  border: 1px solid rgba(0, 0, 0, 0.228);
}

.form_enter textarea {
  margin-top: 6px;
  text-align: left;
  width: 90%;
  min-width: calc(300px + 10px);
  resize: none;
}

.form_enter select {
  width: 130px;
  text-align-last: center;
  -moz-text-align-last: center;
}

.wrapper_radios{
  width: 100%;
}

.wrapper_radios label{
  width : 60px !important;
}


/*  mise en forme des controles */
.form_enter input[type="button"],
.form_enter input[type="submit"] {
  margin: 15px;
  padding: 10px;
}
.form_enter input[type="button"]:hover,
.form_enter input[type="submit"]:hover {
  padding: 9px;
  scale: 1.1;
}
