/*
 _____ _       _           _ 
|  __ | |     | |         | |
| |  \| | ___ | |__   __ _| |
| | __| |/ _ \| '_ \ / _` | |
| |_\ | | (_) | |_) | (_| | |
 \____|_|\___/|_.__/ \__,_|_|*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  height: 100%;
}

.container {
  max-width: 1100px;
  margin: auto;
  padding: 2px;
}

@media (min-width: 390px) {
  .container {
    padding: 10px;
  }
}

.tape {
  display: none;
  margin-bottom: -50px;
  font-size: 10px;
  height: 72px;
  @media (min-width: 768) {
    height: 44px;
  }
}

@media (min-height: 732px) {
  .tape {
    display: block;
  }
}

body {
  font-family: var(--main-font);
  font-size: 1.4rem;
  margin: 0; /*enleve la marge autour de la page */
  line-height: 1.6;
  height: 100%;
}

/*
* on  defini ici les break point  de l'application 
*/
main {
  width: 360px;
  margin: auto;
}

@media screen and (min-width: 390px) {
  main {
    width: 390px;
  }
}

@media screen and (min-width: 428px) {
  main {
    width: 428px;
  }
}

section {
  background-color: white;
}

h1,
h2,
h3 {
  font-family: var(--title-font);
  text-align: center;
  font-weight: normal;
}

h1 {
  font-size: 2.6rem;
  position: relative;
  top: -105px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  margin-bottom: -40px;
}

@media screen and (min-width: 400px) {
  h1 {
    font-size: 3rem;
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

figure {
  margin: 0;
}

a {
  text-decoration: none;
  font-family: var(--title-font);
}

img {
  max-width: 100%;
  display: inline-block;
}

.clear {
  clear: both;
}

.hidden {
  display: none;
}

/* .basicBtn{
    padding: 10px;
    background-color: var(--color-1-light);
    border-radius: 10px;
    color: var(--txt-color-light);
    text-transform: uppercase;
    font-weight: bold;
} 

.basicBtn a {
  color: var(--txt-color-light);
} */

/********** Pour avoir le footer en bas de page  ********/
.page-wrapper {
  display: block;
  min-height: 100vh;
  margin-bottom: -70px;
  overflow-x: scroll;
}

.page-wrapper:after {
  content: "";
  display: block;
}
.site-footer,
.page-wrapper:after {
  height: 50px;
}

/******* Pour faire clignotter un texte (err)   ********/
.blinck {
  animation-duration: 1.2s;
  animation-name: blinck;
  animation-iteration-count: 3;
  transition: ease-in-out;
}
@keyframes blinck {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/********** Pour bloquer le scroll vertical   ********/
.blockedY {
  overflow-y: hidden;
}

/** enlève les fleches des inputs de type number ****/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
