.adjust{
  position: relative;
}


.form_reEnter {
  composes: form_enter from "../NewTrade/newTrade.module.css";
}

.input_wrap,
.input_wrapLong {
  display: block;
  width: 45%;
  text-align: left;
}

.form_reEnter .label {
  width: 28% !important;
}

.form_reEnter .input_wrap {
  width: 68% !important;
}

.local_wrapper{
  margin: auto;
}
/* Attention pour les dimensions du meter indispensable de passer
par des variables ( et de les setter 2* -> pour la div container et 
Pour le svg) */
.adjust_page {
  --metW: 70vw;
  --metH: calc((var(--metW)) * 0.60);
  text-align: center;
  width: var(--metW);
  height: var(--metH);
  margin: auto;
}

@media screen and (min-width: 480px) {
  .adjust_page {
    --metW: 320px !important;
    --metH: 185px;
    text-align: center;
  width: var(--metW);
  height: var(--metH);
  margin: auto;
  
  }
}

:export {
  meterWidth: var(--metW);
  meterHeight: var(--metH);
}

.next_prev {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.next,
.prev {
  margin-top: 10px;
  padding: 5px;
  background-color: rgba(211, 211, 211, 0.26);
  border-radius: 50%;
}