
.wrapper{
    width: 100%;
    margin: auto;
    border-radius: 30px;
    margin-bottom: 20px;
    box-shadow: 1px 2px 10px 1px rgb(201, 201, 201);
    padding: 10px;
}

@media  screen and (min-width:430px) {
   .wrapper{
    width: 430px;
    }
}

/* Attention pour les dimensions du meter indispensable de passer
par des variables ( et de les setter 2* -> pour la div container et 
Pour le svg) */

.global_portfolio{
    --metW :80vw;
    --metH : calc((var(--metW))*0.58);
    text-align: center;
   /* width: 430px;*/
    margin: auto;
}

.global_portfolio h2{
    height: 50px;
}

@media  screen and (min-width:480px) {
   .global_portfolio{
    --metW :400px;
    --metH :230px; 
    }
}

/* .global_portfolio h1{
    position: relative;
    top :-90px;
    left: calc(50vw);
    transform: translateX(-50%);
    white-space: nowrap;
    margin-bottom:-40px;
} */


:export {
    meterWidth: var(--metW);
    meterHeight: var(--metH);
}

.global_portfolio .meter_container {
    text-align: center;
    width: var(--metW);
    height: var(--metH); 
    margin: auto;
}

