.form_create {
    display: flex;
    flex-direction: raw;
    width: 100%;
    max-width: 400px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 30px;
    margin-bottom: 20px;
    box-shadow: 1px 2px 10px 1px rgb(201, 201, 201);
    
}

.form_create  label { 
    margin-top: 6px;
    margin-right: 10px;
    display: block;
    width: 160px;
    text-align: right;
}

.form_create input{
    display: block;
    width: 200px;
    border-radius: 10px;
    padding: 4px;
    margin: 2px;
    text-align: right;
    border: 1px solid black;
}

.form_create .btns{
    width: 100%;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
}

.form_create .btns input[name="abandon"], .btns input[name="validation"]{
    width: 45%;
    text-align: center;
}
 
 

 
