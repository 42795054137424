.button {
  font-size: 2rem;
}

.navContainer {
  display: flex;
  height: 100vh;
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 75px;
  padding: 1rem;
  background: transparent;
  color: var(--txt-color-dark);
}

.navbar_links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 100;
  bottom: 0;
  width: 0;
  height: 100%;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.236);
  left: 0;
  align-items: center;
  visibility: hidden;
  transition: all 0.4s linear;
}

.show_nav .navbar_links {
  right: 0;
  width: 100vw;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.468);
}

.navbar_link {
  padding: 0 0.3rem;
  color: inherit;
  display: block;
  padding: 15px;
  font-size: 2.5rem;
  flex-direction: column;
  white-space: nowrap;
  border-bottom: 1px gray solid;
}

.navbar_link:hover {
  padding: 12px;
  font-size: 2.5rem;
  border: 3px solid black;
   border-bottom: 4px solid  black;
}

.navbar_link:hover .navbar_item {
  display: none;
}

.navbar_item {
  width: 100%;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
}

@media screen and (min-width: 672px) {
  .navbar_item {
    width: 50%;
    max-width: 400px;
  }
  .navbar_links {
    align-items: flex-start;
  }
}

/**********************  burger  ****************/
.navbar_burger {
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  /* color: inherit; */
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 0rem;
  right: 1 rem;
  z-index: 10;
}

.burger_bar,
.burger_bar::before,
.burger_bar::after {
  display: block;
  width: 40px;
  height: 3px;
  position: relative;
  border-radius: 3px;
  background-color: rgba(6, 6, 6, 0.557);
  transition: all 0.3s ease-in-out;
}

.navbar_burger:hover .burger_bar,
.navbar_burger:hover .burger_bar::before,
.navbar_burger:hover .burger_bar::after {
  background-color: black;
}

.burger_bar::before,
.burger_bar::after {
  content: "";
  position: absolute;
  left: 0;
}
.burger_bar::before {
  transform: translateY(-12px);
}
.burger_bar::after {
  transform: translateY(12px);
}

/* pour faire disparaitre la barre milieu */
.show_nav .burger_bar {
  background: transparent  !important;
}

.show_nav .burger_bar::before {
  transform: rotate(45deg);
}
.show_nav .burger_bar::after {
  transform: rotate(-45deg);
}
