.re_enter{
    width: 400px;
    margin: auto;
}

/* .re_enter h1{
    position: relative;
    top :-90px;
    white-space: nowrap;
    margin-bottom: -40px;
} */

.form_reEnter {
    composes: form_enter from "../NewTrade/newTrade.module.css"
}
 
.input_wrap, .input_wrapLong {
  display: block;
  width: 45%;
  text-align: left;
}