.navPortfoliosContainer {
  background-color: var(--color-bgTableaux);
  width: 100%;
  border-top: rgba(0, 0, 0, 0.234) 1px solid;
  border-bottom: rgba(0, 0, 0, 0.294) 1px solid; 
  overflow-y: auto;
}

.navPortfolios{
  width: fit-content;
  margin: auto;
  display: flex;
  min-height: 30px;
}

.button {
  font-size: 18px;
  padding: 5px 10px;
  text-decoration: none;
  height: 100%;
  white-space: nowrap;
  color: #545e6f;
  background: #f0f0f0;
} 
.active{
  color: #fff;
  background: #696969;
}