.credits {
  text-align: center;
  position: realative;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.retour {
  margin-top: 100px;
}
  
.links {
 
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 1.25rem;
  padding: 10px 20px;
  margin: 1rem;
}

/******************BOX-SHADOW**************************/


.links {
  background: linear-gradient(to right, #64f38c 0%, 50%, #f79d00 100%);
}

.links:hover {
  animation: neon-box-3 1s infinite alternate linear;
}

@keyframes neon-box-3 {
  from {
    box-shadow: 2px 0 6px rgba(247, 157, 0, 0.7),
      -2px 0 6px rgba(100, 243, 140, 0.7), 5px 0 10px rgba(247, 157, 0, 0.7),
      -5px 0 10px rgba(100, 243, 140, 0.7);
  }

  to {
    box-shadow: 10px 0 20px rgba(247, 157, 0, 0.7),
      -10px 0 20px rgba(100, 243, 140, 0.7), 20px 0 40px rgba(247, 157, 0, 0.7),
      -20px 0 40px rgba(100, 243, 140, 0.7);
  }
}
