
/* .managePort h1 {
  position: relative;
    top :-90px;
    left: calc(50vw);
    transform: translateX(-50%);
    white-space: nowrap;
    margin-bottom: -40px;

} */

.managePort table{
    table-layout: fixed;
    width: 100%;
    max-width: 500px;
    margin: 30px auto 0 auto;
    border-collapse: collapse;
    border: 2px solid var(--color-bgTableaux);
    position: relative;
     
}

.managePort th, .managePort td  {
  width:50px;
  text-align: center;
  border: 2px solid var(--color-bgTableaux);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}





.managePort td {
  padding: 3px;
  text-align: right;
}

.managePort th:first-of-type,
.managePort td:first-of-type  {
  width:70px;
  padding-right:10px;
  text-align: center;
}











.managePort th:nth-of-type(2),
.managePort td:nth-of-type(2){
  width:10px;
}

.managePort tr:nth-last-of-type(even){
    background-color: var(--color-bgTableaux);
}

.wrapper{
margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.btns{
  margin: auto;
}

.btns input[type="button"],
.btns input[type="submit"]{
  margin: 10px;
  padding: 10px;
}
.btns input[type="button"]:hover,
.btns input[type="submit"]:hover{
  padding: 9px;
}