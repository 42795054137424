.modal {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background: rgba(49,49,49,0.8);
    z-index: 10;
}

.content {
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    font-size: 1.8rem;
    background: #f1f1f1;
    padding: 28px 28px;
    border-radius: 10px;
    max-width: 600px;
    min-width: 300px;
}

.btn_close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 1px 2px;
    border: none;
}

.btn_close:hover{
     cursor: pointer;
}
